import { makeStyles } from "@material-ui/core";

const otherStyle = makeStyles((theme) => ({
  containerDownloadButton: {
    textAlign: "center",
    margin: "6px 0px 5px 0px",
  },
}));

export default otherStyle;
