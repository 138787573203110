import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { messages } from "./languages";

function capitalizeWords(string) {
  return string
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const originalT = i18n.t.bind(i18n);

i18n.t = (key, options = {}) => {
  if (options.skipCapitalization) {
    return originalT(key, options);
  }

  const text = originalT(key, options);
  return capitalizeWords(text);
};

i18n.use(LanguageDetector).init({
  debug: false,
  defaultNS: ["translations"],
  fallbackLng: "en",
  ns: ["translations"],
  resources: messages,
});

export { i18n };
