import React from 'react';
import Title from '../Title'; // Assuming Title is another component
import TicketListItem from '../TicketListItem'; // Assuming TicketListItem is another component
import i18n from 'i18next'; // Localization library
import TicketsSkeleton from '../TicketsListSkeleton';

const TicketsSubListComponent = ({
  tickets,
  titleKey,
  idGraf,
  loading,
  handleTicketStatusChange
}) => {
  if (!tickets || tickets.length === 0) {
    return null;
  }

  return (
    <>
      <Title variant="h6" padding="15px">{i18n.t(titleKey)}</Title>
      {tickets
        .filter(ticket => ticket.idGraf === idGraf)
        .map(ticket => (
          <TicketListItem
            key={ticket.id}
            ticket={ticket}
            onTicketStatusChange={handleTicketStatusChange}
          />
        ))
      }
      {loading && tickets.length === 0 && <TicketsSkeleton />}
    </>
  );
};

export default TicketsSubListComponent;
