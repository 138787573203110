import openSocket from "socket.io-client";
import { getBackendUrl } from "../config";
function connectToSocket() {
  let userId = null;
  if (localStorage.getItem("userId")) {
    userId = localStorage.getItem("userId");
  }
  const token = localStorage.getItem("token");
  const socket = openSocket(getBackendUrl(), {
    query: {
      token: JSON.parse(token),
      userId
    },
    transports: ['websocket']
  });

  return socket;
}

export default connectToSocket;
