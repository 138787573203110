import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { FormControl } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { AuthContext } from "../../context/Auth/AuthContext";
import { isArray, capitalize } from "lodash";
import { useIsChatViewRoute } from "../../hooks/useViewRoute";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  limitter: {
    backgroundColor: "red",
    marginBottom: -25,
  },
}));

const ScheduleSchemaChatView = Yup.object().shape({
  body: Yup.string().min(5, "Mensagem muito curta").required("Obrigatório"),
  sendAt: Yup.string().required("Obrigatório"),
});

const ScheduleSchemaDefault = Yup.object().shape({
  body: Yup.string().min(5, "Mensagem muito curta").required("Obrigatório"),
  contactId: Yup.number().required("Obrigatório"),
  sendAt: Yup.string().required("Obrigatório"),
});

const ScheduleModal = ({
  open,
  onClose,
  scheduleId,
  contactId,
  reload,
  isFromInput,
  ticketId,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const initialState = {
    body: "",
    contactId: "",
    sendAt: moment().add(1, "hour").format("YYYY-MM-DDTHH:mm"),
    sentAt: "",
  };

  const initialContact = {
    id: "",
    name: "",
  };

  const [schedule, setSchedule] = useState(initialState);
  const [currentContact, setCurrentContact] = useState(initialContact);
  const [contacts, setContacts] = useState([initialContact]);
  const isChatViewRoute = useIsChatViewRoute();

  useEffect(() => {
    if (contactId && contacts.length) {
      const contact = contacts.find((c) => c.id === contactId);
      if (contact) {
        setCurrentContact(contact);
      }
    }
  }, [contactId, contacts]);

  useEffect(() => {
    try {
      (async () => {
        const idGraf = localStorage.getItem("idGraf");
        const { data: contactList } = await api.get(
          `/contacts/list?idGraf=${idGraf}`
        );
        let customList = contactList.map((c) => ({ id: c.id, name: c.name }));
        if (isArray(customList)) {
          setContacts([{ id: "", name: "" }, ...customList]);
        }
        if (contactId) {
          setSchedule((prevState) => {
            return { ...prevState, contactId };
          });
        }

        if (!scheduleId) return;
        const { data } = await api.get(`/schedules/${scheduleId}/${idGraf}`);
        setSchedule((prevState) => {
          return {
            ...prevState,
            ...data,
            sendAt: moment(data.sendAt).format("YYYY-MM-DDTHH:mm"),
          };
        });
        setCurrentContact(data.contact);
      })();
    } catch (err) {
      toastError(err);
    }
  }, [scheduleId, contactId, open]);

  const handleClose = () => {
    onClose();
    setSchedule(initialState);
  };

  const handleSaveSchedule = async (values) => {
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const idGraf = localStorage.getItem("idGraf");
    const scheduleData = {
      ...values,
      userId: user.id,
      idGraf,
      timezone: clientTimezone,
      ticketId,
    };
    if (!isChatViewRoute) {
      try {
        if (scheduleId) {
          await api.put(`/schedules/${scheduleId}/${idGraf}`, scheduleData);
        } else {
          await api.post("/schedules", scheduleData);
        }
        toast.success(i18n.t("scheduleModal.success"), {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        if (typeof reload == "function") {
          reload();
        }
        setCurrentContact(initialContact);
        setSchedule(initialState);
        if (!isFromInput) {
          history.push("/schedules");
        }
      } catch (err) {
        toastError(err);
      }
    } else {
      try {
        await api.post("/schedules", scheduleData);
        toast.success(i18n.t("scheduleModal.success"), {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } catch (err) {
        toastError(err);
      }
    }
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {schedule.status === "ERRO"
            ? "Erro de Envio"
            : `Mensagem ${capitalize(schedule.status)}`}
        </DialogTitle>
        <Formik
          initialValues={schedule}
          enableReinitialize={true}
          validationSchema={
            isChatViewRoute ? ScheduleSchemaChatView : ScheduleSchemaDefault
          }
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveSchedule(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values }) => (
            <Form>
              <DialogContent dividers>
                {!isChatViewRoute ? (
                  <>
                    <div className={classes.multFieldLine}>
                      <FormControl variant="outlined" fullWidth>
                        <Autocomplete
                          fullWidth
                          value={currentContact}
                          options={contacts}
                          onChange={(e, contact) => {
                            const contactId = contact ? contact.id : "";
                            setSchedule({ ...schedule, contactId });
                            setCurrentContact(
                              contact ? contact : initialContact
                            );
                          }}
                          getOptionLabel={(option) => option.name}
                          getOptionSelected={(option, value) => {
                            return value.id === option.id;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Contato"
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classes.limitter}></div>
                  </>
                )}

                <br />
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    rows={9}
                    multiline={true}
                    label={i18n.t("scheduleModal.form.body")}
                    name="body"
                    error={touched.body && Boolean(errors.body)}
                    helperText={touched.body && errors.body}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </div>
                <br />
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    label={i18n.t("scheduleModal.form.sendAt")}
                    type="datetime-local"
                    name="sendAt"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={touched.sendAt && Boolean(errors.sendAt)}
                    helperText={touched.sendAt && errors.sendAt}
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("scheduleModal.buttons.cancel")}
                </Button>
                {(schedule.sentAt === null || schedule.sentAt === "") && (
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                    className={classes.btnWrapper}
                  >
                    {scheduleId
                      ? `${i18n.t("scheduleModal.buttons.okEdit")}`
                      : `${i18n.t("scheduleModal.buttons.okAdd")}`}
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ScheduleModal;
