import api from "../../services/api";

const useQueues = () => {
	const findAll = async () => {
        const idGraf = localStorage.getItem("idGraf");
        const { data } = await api.get("/queue" , { params: { idGraf : idGraf } });
        return data;
    }

	return { findAll };
};

export default useQueues;
