const countryMasks = {
    'US': '(999) 999-9999',
    'RU': '9 (999) 999-99-99',
    'EG': '9999 999 999',
    'ZA': '999 999 9999',
    'GR': '99 9999 9999',
    'NL': '999 999 9999',
    'BE': '999 99 99 99',
    'FR': '99 99 99 99 99',
    'ES': '999 99 99 99',
    'HU': '99 999 9999',
    'IT': '999 999 9999',
    'RO': '999 999 999',
    'CH': '99 999 99 99',
    'AT': '999 9999999',
    'GB': '99999 999999',
    'DK': '99 99 99 99',
    'SE': '999-999 99 99',
    'NO': '999 99 999',
    'PL': '999 999 999',
    'DE': '9999 9999999',
    'PE': '999 999 999',
    'MX': '99 99 99 99',
    'CU': '99 999999',
    'AR': '99 9999-99999',
    'BR': '(99) 999999999',
    'CL': '99 999 9999',
    'CO': '999 999 9999',
    'VE': '999-9999999',
    'MY': '999-999 9999',
    'AU': '9999 999 999',
    'ID': '9999-999-999',
    'PH': '9999 999 999',
    'NZ': '99 999 9999',
    'SG': '9999 9999',
    'TH': '99 999 9999',
    'JP': '99-9999-9999',
    'KR': '999-9999-9999',
    'VN': '99 9999 999',
    'CN': '999 9999 9999',
    'TR': '999 999 99 99',
    'IN': '99999-99999',
    'PK': '999 9999999',
    'AF': '999 999 999',
    'LK': '999 999 9999',
    'MM': '99 999 999',
    'IR': '999 999 9999',
    'SS': '999 999 999',
    'MA': '9999-999999',
    'DZ': '999 99 99 99',
    'TN': '99 999 999',
    'LY': '999-9999999',
    'GM': '999 9999',
    'SN': '99 999 9999',
    'MR': '99 99 99 99',
    'ML': '99 99 99 99',
    'GN': '999 99 99 99',
    'CI': '99 99 99 99',
    'BF': '99 99 99 99',
    'NE': '99 99 99 99',
    'TG': '99 999 999',
    'BJ': '99 99 99 99',
    'MU': '999 9999',
    'LR': '999 999 999',
    'SL': '99 999999',
    'GH': '999 999 9999',
    'NG': '999 999 9999',
    'TD': '99 99 99 99',
    'CF': '99 99 99 99',
    'CM': '9999 999 999',
    'CV': '999 99 99',
    'ST': '999 999 999',
    'GQ': '999 999 999',
    'GA': '99 99 99 99',
    'CG': '99 999 9999',
    'CD': '99 999 9999',
    'AO': '999 999 999',
    'GW': '999 999 999',
    'IO': '999 999 999',
    'SC': '999 9999',
    'SD': '999 999 9999',
    'RW': '999 999 999',
    'ET': '999 999 9999',
    'SO': '99 9999 999',
    'DJ': '99 99 99 99',
    'KE': '999 999999',
    'TZ': '999 999 999',
    'UG': '999 999 999',
    'BI': '99 99 99 99',
    'MZ': '99 999 9999',
    'ZM': '999 999 9999',
    'MG': '99 99 999 99',
    'RE': '999 99 99 99',
    'ZW': '999 999 999',
    'NA': '99 999 9999',
    'MW': '999 99 99 99',
    'LS': '999 999 999',
    'BW': '99 999 999',
    'SZ': '99 999 9999',
    'KM': '999 99 99',
    'SH': '999 999 999',
    'ER': '999 999 999',
    'AW': '999 9999',
    'FO': '999 999',
    'GL': '99 99 99',
    'GI': '999 99999',
    'PT': '999 999 999',
    'LU': '999 999 999',
    'IE': '999 999 999',
    'IS': '999 9999',
    'AL': '999 999 999',
    'MT': '9999 9999',
    'CY': '99 999999',
    'FI': '99 999 9999',
    'BG': '999 999 999',
    'LT': '999 99 999',
    'LV': '999 99999',
    'EE': '999 9999',
    'MD': '999 99 9999',
    'AM': '99 999 999',
    'BY': '99 999 99 99',
    'AD': '999 999',
    'MC': '99 99 99 99',
    'SM': '99 99 99 99',
    'VA': '99 999 999',
    'UA': '999 999 9999',
    'RS': '99 999 999',
    'ME': '99 999 999',
    'XK': '999 999 999',
    'HR': '99 999 9999',
    'SI': '999 999 999',
    'BA': '99 999 999',
    'MK': '99 999 999',
    'CZ': '999 999 999',
    'SK': '999 999 999',
    'LI': '999 999 999',
    'FK': '999 9999',
    'BZ': '999-9999',
    'GT': '9999 9999',
    'SV': '9999 9999',
    'HN': '9999 9999',
    'NI': '9999 9999',
    'CR': '9999 9999',
    'PA': '999 9999',
    'PM': '999 99 99',
    'HT': '99 99 9999',
    'GP': '999 99 99 99',
    'BO': '999 99999',
    'GY': '999 9999',
    'EC': '99 999 9999',
    'GF': '999 99 99 99',
    'PY': '999 999 999',
    'MQ': '999 99 99 99',
    'SR': '999 9999',
    'UY': '999 999 99',
    'AN': '999 9999',
    'TL': '999 999 999',
    'NF': '999 999 999',
    'BN': '999 9999',
    'NR': '999 9999',
    'PG': '9999 9999',
    'TO': '9999 999',
    'SB': '9999 999',
    'VU': '999 9999',
    'FJ': '99 99999',
    'PW': '999 9999',
    'WF': '99 99 99',
    'CK': '99 999',
    'NU': '999 999',
    'WS': '99 999',
    'KI': '99 999',
    'NC': '99 999 99',
    'TV': '99 999',
    'PF': '99 99 99',
    'TK': '99 999',
    'FM': '999 9999',
    'MH': '999 9999',
    'KP': '999 999 999',
    'HK': '9999 9999',
    'MO': '9999 9999',
    'KH': '99 999 999',
    'LA': '99 999 999',
    'BD': '99999 999999',
    'TW': '9999 999 999',
    'MV': '999 9999',
    'LB': '99 999 999',
    'JO': '999 999 999',
    'SY': '999 999 999',
    'IQ': '999 999 999',
    'KW': '9999 9999',
    'SA': '999 999 9999',
    'YE': '999 999 999',
    'OM': '9999 9999',
    'PS': '999 999 999',
    'AE': '999 999 9999',
    'IL': '999 999 9999',
    'BH': '9999 9999',
    'QA': '9999 9999',
    'BT': '999 999 999',
    'MN': '99 99 99 99',
    'NP': '999-9999999',
    'TJ': '99 999 9999',
    'TM': '99 999 999',
    'AZ': '999 999 99 99',
    'GE': '99 999 99 99',
    'KG': '999 999 999',
    'UZ': '99 999 99 99'
};

export default countryMasks;
