import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import Icon from "../Icon";
import ConfirmationModal from "../ConfirmationModal";
import TransferTicketModal from "../TransferTicketModal";
import PubSub from 'pubsub-js';
import { useIsChatViewRoute } from "../../hooks/useViewRoute";

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    marginRight: "40px",
    flex: "none",
    alignSelf: "center",
    marginLeft: "auto",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "-1000px",
  },
  button: {
    marginRight: "-15px",
  },
  resolveButton: {
    marginLeft: "50px",
  },
  buttonAccept: {
    marginLeft: "20px",
    "&:hover": {
      backgroundColor: "#1769AA",
      color: "white",
    },
  },
}));

const TicketActionButtons = ({ ticket, onToggleTags }) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const ticketOptionsMenuOpen = Boolean(anchorEl);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
  const [statusTicket, setStatusTicket] = useState(null);
  const { user } = useContext(AuthContext);
  const isChatViewRoute = useIsChatViewRoute();
  const isMyTicket = ticket.userId ? user.id === ticket.userId : false;
  const tranfer = !isMyTicket ? false : ticket.status === "open"

  const handleCloseTicketOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const handleUpdateTicketStatus = async (e, status, userId) => {
    setLoading(true);
    try {
      const idGraf = localStorage.getItem("idGraf");
      await api.put(`/tickets/${ticket.id}/${idGraf}`, {
        status: status,
        userId: userId || null,
      });

      try {
        const { data } = await api.get(`/getClosedTickets/${idGraf}`);
        PubSub.publish("TICKET_ACTION_UPDATED", { ticketsClosed: data.ticketsClosed, newStatus: status });
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }

      setLoading(false);
      if (!isChatViewRoute) {
        if (status === "open") {
          history.push(`/tickets/${ticket.id}`);
        } else {
          history.push("/tickets");
        }
      } else {
        history.push(`/ChatViewCrm/${ticket.id}`);
      }

    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  const handleDeleteTicket = async () => {
    try {
      const idGraf = localStorage.getItem("idGraf");
      await api.delete(`/tickets/${ticket.id}/${idGraf}`);
      PubSub.publish("TICKET_ACTION_UPDATED", { newStatus: "deleted" });
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenConfirmationModal = (e) => {
    setConfirmationOpen(true);
  };

  const handleOpenTransferModal = (e, status) => {
    setStatusTicket(status);
    setTransferTicketModalOpen(true);
  };

  const handleCloseTransferTicketModal = () => {
    setTransferTicketModalOpen(false);
  };

  const handleSaveTransferTicketModal = () => {
    if (isChatViewRoute) {
      history.push('/tickets/');
      setTimeout(() => history.push(`/ChatViewCrm/${ticket.id}`), 100)
    } else {
      history.push(`/tickets/`);
      setTimeout(() => history.push(`/tickets/${ticket.id}`), 100)
    }
  };

  return (
    <div className={classes.actionButtons}>
      <div className={classes.buttonContainer}>
        {ticket.status === "open" && (
          <>
            <ButtonWithSpinner
              className={classes.button}
              loading={loading}
              size="small"
              onClick={onToggleTags}
            >
              <Icon type="tagsTicket" />
            </ButtonWithSpinner>

            {(!isChatViewRoute && isMyTicket) ? (
              <>
                <ButtonWithSpinner
                  className={classes.button}
                  loading={loading}
                  size="small"
                  onClick={(e) => handleOpenTransferModal(e, "pending", null)}
                >
                  <Icon type="transferTicket" />
                </ButtonWithSpinner>

                <ButtonWithSpinner
                  className={classes.button}
                  loading={loading}
                  size="small"
                  onClick={(e) =>
                    handleUpdateTicketStatus(e, "pending", null)
                  }
                >
                  <Icon type="returnPending" />
                </ButtonWithSpinner>

                <ButtonWithSpinner
                  className={classes.button}
                  loading={loading}
                  onClick={(e) => handleOpenConfirmationModal()}
                >
                  <Icon type="deleteTicket" />
                </ButtonWithSpinner>
              </>
            ) : (
              <>
                {isMyTicket && (
                  <>
                    <ButtonWithSpinner
                      className={classes.button}
                      loading={loading}
                      size="small"
                      onClick={(e) => handleOpenTransferModal(e, "pending", null)}
                    >
                      <Icon type="transferTicket" />
                    </ButtonWithSpinner>
                  </>
                )}
              </>
            )}

            {!isChatViewRoute && isMyTicket ? (
              <>
                <ButtonWithSpinner
                  className={classes.resolveButton}
                  loading={loading}
                  onClick={(e) =>
                    handleUpdateTicketStatus(e, "closed", user?.id)
                  }
                >
                  <Icon type="resolveTicket" />
                </ButtonWithSpinner>
              </>
            ) : (
              <></>
            )}

            <ConfirmationModal
              title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")}${ticket.id
                } ${i18n.t("ticketOptionsMenu.confirmationModal.titleFrom")} ${ticket.contact.name
                }?`}
              open={confirmationOpen}
              onClose={setConfirmationOpen}
              onConfirm={handleDeleteTicket}
            >
              {i18n.t("ticketOptionsMenu.confirmationModal.message")}
            </ConfirmationModal>
            <TicketOptionsMenu
              ticket={ticket}
              anchorEl={anchorEl}
              menuOpen={ticketOptionsMenuOpen}
              handleClose={handleCloseTicketOptionsMenu}
            />
          </>
        )}
        {!isMyTicket && (
          <>
            <ButtonWithSpinner
              className={classes.buttonAccept}
              loading={loading}
              size="small"
              variant="contained"
              color="primary"
              onClick={(e) => handleOpenTransferModal(e, "open")}
            >
              {i18n.t("messagesList.header.buttons.accept")}
            </ButtonWithSpinner>

          </>
        )}

        {transferTicketModalOpen && (
          <TransferTicketModal
            modalOpen={transferTicketModalOpen}
            onClose={handleCloseTransferTicketModal}
            onSave={handleSaveTransferTicketModal}
            ticketid={ticket.id}
            ticketWhatsappId={ticket.whatsappId}
            ticketQueueId={ticket.queueId}
            ticketStatus={statusTicket}
            transfer={tranfer}
            isChatViewRoute
          />
        )}
        
      </div>
    </div>
  );
};

export default TicketActionButtons;
