import React, { useState, useEffect, useContext, useReducer } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import useQueues from "../../hooks/useQueues";
import PubSub from "pubsub-js";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";

const useStyles = makeStyles((theme) => ({
  maxWidth: {
    width: "100%",
  },
  buttonCancel: {
    borderColor: "#f50057",
    color: "#f50057",
    '&:hover': {
      backgroundColor: "#f50057",
      color: "#fff",
    }
  },
}));

const filterOptions = createFilterOptions({
  trim: true,
});

const reducer = (state, action) => {
  if (action.type === "LOAD_WHATSAPPS") {
    const whatsApps = action.payload;

    return [...whatsApps];
  }

  if (action.type === "UPDATE_WHATSAPPS") {
    const whatsApp = action.payload;
    const whatsAppIndex = state.findIndex((s) => s.id === whatsApp.id);

    if (whatsAppIndex !== -1) {
      state[whatsAppIndex] = whatsApp;
      return [...state];
    } else {
      return [whatsApp, ...state];
    }
  }

  if (action.type === "UPDATE_SESSION") {
    const whatsApp = action.payload;
    const whatsAppIndex = state.findIndex((s) => s.id === whatsApp.id);

    if (whatsAppIndex !== -1) {
      state[whatsAppIndex].status = whatsApp.status;
      state[whatsAppIndex].updatedAt = whatsApp.updatedAt;
      state[whatsAppIndex].qrcode = whatsApp.qrcode;
      state[whatsAppIndex].retries = whatsApp.retries;
      state[whatsAppIndex].phoneNumber = whatsApp.phoneNumber;
      return [...state];
    } else {
      return [...state];
    }
  }

  if (action.type === "DELETE_WHATSAPPS") {
    const whatsAppId = action.payload;

    const whatsAppIndex = state.findIndex((s) => s.id === whatsAppId);
    if (whatsAppIndex !== -1) {
      state.splice(whatsAppIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const TransferTicketModal = ({ modalOpen, onClose, ticketid, ticketWhatsappId, ticketQueueId, ticketStatus = null, onSave = null, transfer = false, isChatViewRoute = false }) => {
  const history = useHistory();
  const [options, setOptions] = useState([]);
  const [queues, setQueues] = useState([]);
  const [allQueues, setAllQueues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedQueue, setSelectedQueue] = useState('');
  const [selectedWhatsapp, setSelectedWhatsapp] = useState(ticketWhatsappId);
  const classes = useStyles();
  const { findAll: findAllQueues } = useQueues();
  const [loadingWhatsapps, setLoadingWhatsapps] = useState([]);
  const [whatsApps, dispatch] = useReducer(reducer, []);
  const { user: loggedInUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchSession = async () => {
      try {
        setLoadingWhatsapps(true);
        const idGraf = localStorage.getItem("idGraf");
        const { data } = await api.get(`/whatsapp/${idGraf}`);
        dispatch({
          type: "LOAD_WHATSAPPS",
          payload: data.filter((item) => item.idGraf === idGraf),
        });
        setLoadingWhatsapps(false);
      } catch (err) {
        setLoadingWhatsapps(false);
        toastError(err);
      }
    };

    fetchSession()
  }, [modalOpen]);


  useEffect(() => {
    const loadQueues = async () => {
      const list = await findAllQueues();
      setAllQueues(list);
      setQueues(list);
    }
    loadQueues();
  }, []);

  useEffect(() => {
    const defaultDepartment = whatsApps.find((e) => e.id === ticketWhatsappId)?.defaultDepartment;
    setSelectedUser(loggedInUser);

    if (ticketQueueId) {
      setSelectedQueue(ticketQueueId);
    } else if (defaultDepartment) {
      setSelectedQueue(defaultDepartment);
    }

    setLoaded(true);
  }, [ticketWhatsappId, ticketQueueId, ticketStatus, whatsApps, loggedInUser,]);

  useEffect(() => {
    if (!modalOpen || searchParam.length < 3) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchUsers = async () => {
        try {
          const idGraf = localStorage.getItem("idGraf");
          const { data } = await api.get("/users/", {
            params: { searchParam, idGraf },
          });
          setOptions(data.users);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };

      fetchUsers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, modalOpen]);

  const handleClose = () => {
    onClose();
    setSearchParam("");
    setSelectedUser(null);
  };

  const handleSaveTicket = async e => {
    e.preventDefault();
    if (!ticketid) return;
    setLoading(true);
    try {
      let data = {};

      if (selectedUser) {
        data.userId = selectedUser.id
      }

      if (selectedQueue && selectedQueue !== null) {
        data.queueId = selectedQueue

        if (!selectedUser) {
          data.status = 'pending';
          data.userId = null;
        }
      }

      if (selectedWhatsapp) {
        data.whatsappId = selectedWhatsapp;
      }

      if (ticketStatus && ticketStatus !== null) {
        data.status = 'open';
      }

      const idGraf = localStorage.getItem("idGraf");
      await api.put(`/tickets/${ticketid}/${idGraf}`, data);

      setLoading(false);
      if (!isChatViewRoute) history.push(`/tickets`);
      PubSub.publish("TICKET_ACTION_UPDATED", { newStatus: "open" });
      if (onSave !== null) onSave();
      onClose();
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  if (!loaded) {
    return <div>Loading...</div>;
  }

  return (
    <Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
      <form onSubmit={handleSaveTicket}>
        <DialogTitle id="form-dialog-title">
          {!transfer ? i18n.t("transferTicketModal.acceptTitle") : i18n.t("transferTicketModal.title")}
        </DialogTitle>
        <DialogContent dividers>
          <Autocomplete
            style={{ width: 300, marginBottom: 20 }}
            getOptionLabel={option => `${option.name}`}
            onChange={(e, newValue) => {
              setSelectedUser(newValue);
              if (newValue != null && Array.isArray(newValue.queues)) {
                setQueues(newValue.queues);
              } else {
                setQueues(allQueues);
                setSelectedQueue('');
              }
            }}
            options={options}
            filterOptions={filterOptions}
            freeSolo
            autoHighlight
            noOptionsText={i18n.t("transferTicketModal.noOptions")}
            loading={loading}
            value={selectedUser}
            renderInput={params => (
              <TextField
                {...params}
                label={i18n.t("transferTicketModal.fieldLabel")}
                variant="outlined"
                autoFocus
                onChange={e => setSearchParam(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
          <FormControl variant="outlined" className={classes.maxWidth}>
            <InputLabel>{!transfer ? i18n.t("transferTicketModal.acceptFieldQueueLabel") : i18n.t("transferTicketModal.fieldQueueLabel")}</InputLabel>
            <Select
              value={selectedQueue}
              onChange={(e) => setSelectedQueue(e.target.value)}
              label={i18n.t("transferTicketModal.fieldQueuePlaceholder")}
              required
            >
              <MenuItem value={''}>&nbsp;</MenuItem>
              {queues.map((queue) => (
                <MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Can
            role={loggedInUser.profile}
            perform="ticket-options:transferWhatsapp"
            yes={() => (!loadingWhatsapps &&
              <FormControl variant="outlined" className={classes.maxWidth} style={{ marginTop: 20 }}>
                <InputLabel>{i18n.t("transferTicketModal.fieldConnectionLabel")}</InputLabel>
                <Select
                  value={selectedWhatsapp}
                  onChange={(e) => setSelectedWhatsapp(e.target.value)}
                  label={i18n.t("transferTicketModal.fieldConnectionPlaceholder")}
                >
                  {whatsApps.map((whasapp) => (
                    <MenuItem key={whasapp.id} value={whasapp.id}>{whasapp.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.buttonCancel}
            onClick={handleClose}
            color="secondary"
            disabled={loading}
            variant="outlined"
          >
            {i18n.t("transferTicketModal.buttons.cancel")}
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            loading={loading}
          >
            {!transfer ? i18n.t("transferTicketModal.buttons.attend") : i18n.t("transferTicketModal.buttons.ok")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TransferTicketModal;
