import React from "react";
import Typography from "@material-ui/core/Typography";

export default function Title(props) {
  const {
    variant = "h5",
    color = "primary",
    padding = "20px"
  } = props;

  return (
    <Typography variant={variant} color={color} gutterBottom style={{ padding: padding, color: "#656565" }}>
      {props.children}
    </Typography>
  );
}

