import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ModalImage from "react-modal-image";
import useS3 from "../../hooks/useS3";

const useStyles = makeStyles(() => ({
  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
}));

const ModalImageCors = ({ imageUrl }) => {
  const classes = useStyles();
  const { blobUrl } = useS3(imageUrl);

  return (
    <ModalImage
      className={classes.messageMedia}
      smallSrcSet={blobUrl}
      medium={blobUrl}
      large={blobUrl}
      alt="image"
    />
  );
};

export default ModalImageCors;
