import React from "react";
import { GetApp } from "@material-ui/icons";
import pdfStyle from "./css/pdfStyle";
import { Button } from "@material-ui/core";
import useS3 from "../../hooks/useS3";

const PdfCors = ({ pdfUrl }) => {
  const classes = pdfStyle();
  const { blobUrl } = useS3(pdfUrl);

  return (
    blobUrl && pdfUrl ? (
      <div style={{ overflow: "hidden" }}>
        <div style={{ marginRight: "-17px" }}>
          <iframe className={classes.iframe} src={`${blobUrl}#toolbar=0`} />
        </div>
        <div className={classes.containerDownloadButton}>
          <Button
            startIcon={<GetApp />}
            color="primary"
            variant="outlined"
            target="_blank"
            border="none"
            href={blobUrl}
          >
            Download
          </Button>
        </div>
      </div>
    ) : null
  );
};

export default PdfCors;
