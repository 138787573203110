import { Badge, makeStyles } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";

import React, { useContext, useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Can } from "../components/Can";
import { AuthContext } from "../context/Auth/AuthContext";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { i18n } from "../translate/i18n";
import { Tooltip } from '@material-ui/core';

import Icon from "../components/Icon";
import UserModal from "../components/UserModal";
import useWhatsApps from "../hooks/useWhatsApps";

const useStyles = makeStyles((theme) => ({
  noShadow: {
    boxShadow: "none !important",
  },

  divider: {
    marginTop: "10px",
    marginBottom: "3px",
    width: "305px",
    height: "1px",
    background: "rgba(0, 0, 0, 0.12)",
    borderRadius: "0px",
  },

  profileStyle: {
    cursor: "pointer",
    marginTop: "9px",
    padding: "11px",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
    "& .label": {
      fontSize: "1rem",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
      position: "relative",
      left: "39px",
      bottom: "2px",
    },
    "& .icon": {
      position: "relative",
      left: "2px",
      top: "2px",
    },
  },

  logOutStyle: {
    cursor: "pointer",
    marginTop: "1px",
    padding: "11px",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
    "& .label": {
      fontSize: "1rem",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
      position: "relative",
      left: "43px",

    },
    "& .icon": {
      position: "relative",
      left: "7px",
      top: "4px",
    },
  },
}));


const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} placement="right" {...props} />;
}

function ListItemLink(props) {
  const { icon, primary, to, className, tooltipTitle } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  const listItem = (
    <ListItem button component={renderLink} className={className}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={primary} />
    </ListItem>
  );
  return (
    <li>
      {tooltipTitle ? <BootstrapTooltip title={tooltipTitle}>{listItem}</BootstrapTooltip> : listItem}
    </li>
  );
}


const MainListItems = (props) => {
  const { drawerClose } = props;
  const { whatsApps } = useWhatsApps();
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);

  const [userModalOpen, setUserModalOpen] = useState(false);
  const profileRef = useRef();
  const { handleLogout } = useContext(AuthContext);

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
  };

  const handleClickLogout = () => {
    handleLogout();
  };

  const classes = useStyles();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  return (
    <div onClick={drawerClose}>

      <ListItemLink
        to="/"
        primary="Dashboard"
        icon={<Icon type="dashboard" />}
        tooltipTitle="Dashboard"
      />

      <ListItemLink
        to="/tickets"
        primary={i18n.t("mainDrawer.listItems.tickets")}
        icon={<Icon type="whatsapp" />}
        tooltipTitle="Conversas"
      />
      <ListItemLink
        to="/contacts"
        primary={i18n.t("mainDrawer.listItems.contacts")}
        icon={<Icon type="contacts" />}
        tooltipTitle="Contatos"
      />

      <ListItemLink
        to="/quickAnswers"
        primary={i18n.t("mainDrawer.listItems.quickAnswers")}
        icon={<Icon type="quickAnswers" />}
        tooltipTitle="Respostas Rápidas"
      />

      <ListItemLink
        to="/schedules"
        primary={i18n.t("mainDrawer.listItems.schedules")}
        icon={<Icon type="schedules" />}
        tooltipTitle="Agendamentos"
      />

      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <div className={classes.divider} />
            <div>
              <ListSubheader inset>
                {i18n.t("mainDrawer.listItems.administration")}
              </ListSubheader>
            </div>

            <ListItemLink
              to="/connections"
              primary={i18n.t("mainDrawer.listItems.connections")}
              icon={
                <Badge
                  overlap="rectangular"
                  badgeContent={connectionWarning ? "!" : 0}
                  color="error"
                >
                  <Icon type="connections" />
                </Badge>
              }
              tooltipTitle="Conexões"
            />
            <ListItemLink
              to="/users"
              primary={i18n.t("mainDrawer.listItems.attendants")}
              icon={<PeopleAltOutlinedIcon />}
              tooltipTitle="Atendentes"
            />
            <ListItemLink
              to="/queues"
              primary={i18n.t("mainDrawer.listItems.departments")}
              icon={<Icon type="departments" />}
              tooltipTitle="Departamentos"
            />
            <ListItemLink
              to="/tags"
              primary={i18n.t("mainDrawer.listItems.tags")}
              icon={<LocalOfferIcon />}
              tooltipTitle="Tags"
            />

            <ListItemLink
              to="/settings"
              primary={i18n.t("mainDrawer.listItems.settings")}
              icon={<SettingsOutlinedIcon />}
              tooltipTitle="Configurações"
            />

            <div className={classes.divider} />
          </>
        )}
      />

      <div ref={profileRef} className={classes.profileStyle}>
        <BootstrapTooltip title="Perfil">
          <div
            className="icon"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenUserModal}
            color="inherit"
          >
            <span className="icon">

              <Icon type="profile" />
            </span>

            <span className="label">Perfil</span>
          </div>
        </BootstrapTooltip>
      </div>

      <BootstrapTooltip title="Sair">
        <div
          ref={profileRef}
          className={classes.logOutStyle}
          onClick={handleClickLogout}
        >
          <span className="icon">
            <Icon type="logout" />
          </span>
          <span className="label">Sair</span>
        </div>
      </BootstrapTooltip>

      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
        myProfile
      />
    </div>
  );
};

export default MainListItems;
